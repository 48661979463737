import React, { FC } from 'react';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { CFAttention } from '../../common/CFIcons/CFAttention';

import Text from '../Text/Text';
import { Checkbox } from '../CheckBox/Checkbox';
import { Input, Textarea, Div, Label } from '../Elements';
import { SelectDropdown } from '../SelectDropdown';
import { MarketoForm, MarketoFormProps } from './marketo-form';

/**
 * There are many places we use forms, this is an opiniontated
 * style one for generic use
 *
 * @param props MarketoFormProps
 * @returns A styled component based on our use for this
 */
export const MarketoFormStyled: FC<MarketoFormProps> = props => {
  const translate = useTranslations();
  const isEnglishOnly = props.isEnglishOnly;
  return (
    <MarketoForm
      {...props}
      isEnglishOnly={isEnglishOnly}
      InputElement={({ value, onChange, id, required, onBlur, onFocus, className, ...other }) => {
        return (
          <Input
            onBlur={onBlur as any} // Elements typescript issue
            onFocus={onFocus as any}
            padding={2}
            border="all"
            className="marketo-input"
            borderColor="gray1"
            width="100%"
            color="black"
            autoComplete={(id as string).indexOf('Company') > -1 ? 'off' : 'on'}
            required={required}
            id={id}
            value={value}
            backgroundColor="transparent"
            focused={{
              outlineColor: 'blue1',
              outlineWidth: 2,
              outlineStyle: 'solid',
              outlineInnerOffset: 2,
              borderColor: 'transparent',
            }}
            onChange={onChange as any}
            {...other}
          />
        );
      }}
      TextAreaElement={({
        value,
        onChange,
        id,
        required,
        onBlur,
        className,
        onFocus,
        ...other
      }) => {
        return (
          <Textarea
            onBlur={onBlur as any} // Elements typescript issue
            onFocus={onFocus as any}
            padding={2}
            marginBottom={2}
            borderColor="gray1"
            width="100%"
            backgroundColor="transparent"
            color="black"
            value={value}
            focused={{
              outlineColor: 'blue1',
              outlineWidth: 2,
              outlineStyle: 'solid',
              outlineInnerOffset: 2,
              borderColor: 'transparent',
            }}
            onChange={onChange as any}
            id={id}
            required={required}
            {...other}
          />
        );
      }}
      SelectElement={({ onChange, value, id, options, required, className, ...other }) => {
        return (
          <SelectDropdown
            marginBottom={2}
            id={id}
            required={required}
            borderColor="gray1"
            color="black"
            value={value}
            onChange={onChange as any}
            {...other}
          >
            {options.map((o, i) => (
              <option value={o.value} key={`${o.value}-${i}`}>
                {isEnglishOnly ? o.label : translate(o.label)}
              </option>
            ))}
          </SelectDropdown>
        );
      }}
      LabelElement={({ htmlFor, text }) => {
        return (
          <Div>
            <Label fontSize={2} lineHeight="copy" fontWeight={6} htmlFor={htmlFor}>
              {isEnglishOnly ? text : translate(text || '')}
            </Label>
          </Div>
        );
      }}
      CheckboxElement={({ id, checked, onChange, labelText, ...other }) => (
        <Checkbox
          id={id || ''}
          onChange={onChange}
          labelText={isEnglishOnly ? labelText : (translate(labelText) as string)}
          labelHtmlFor={id || ''}
          checked={checked || false}
          value="yes"
        />
      )}
      ErrorElement={({ text, children }) => (
        <Div marginBottom={2} lineHeight={1} display="flex" alignItems="center">
          <CFAttention size={16} color="red0" />
          <Text marginLeft={1} color="red0" variant="body3">
            {text}
            {isEnglishOnly ? text : translate(text as string)}
            {children}
          </Text>
        </Div>
      )}
    />
  );
};
