import { Div, makeStyledComponent, Span } from './Elements';
import React from 'react';
import { StyleSystemProps, PseudoProps } from './Elements/Elements';
import { BrandColors, colorMap } from './CFIcons/icon.interface';

export interface SelectDropdownProps extends Partial<Pick<StyleSystemProps, 'borderColor'>> {
  styleprops?: Partial<StyleSystemProps> & PseudoProps;
  arrowColor?: BrandColors;
}

const SelectDropdownUnstyled: React.FC<SelectDropdownProps &
  React.HTMLProps<HTMLSelectElement>> = props => {
  const { onChange, value, children, className, styleprops } = props;
  const [displayValue, setDisplayValue] = React.useState('');
  const selectEl = React.useRef<HTMLSelectElement>(null);

  React.useEffect(() => {
    if (selectEl.current) {
      const option = selectEl.current.querySelector(
        `option[value="${props.value || ''}"]`
      ) as HTMLOptionElement;

      if (option) {
        setDisplayValue(option.innerText);
      }
    }
  }, [value]);

  return (
    <Div position="relative" className={className}>
      <select
        {...props}
        className={`native-dropdown absolute body-2 h-100 f2 w-100 z-1 o-0`}
        value={value}
        onChange={onChange}
        ref={selectEl}
      >
        {children}
      </select>
      <Div
        paddingHorizontal={1}
        paddingVertical={2}
        border="all"
        borderColor={props.borderColor || 'gray1'}
        display="flex"
        zIndex={2}
        flex={'auto'}
        position="relative"
        lineHeight={1}
        className={`pe-none`}
        role="dropdown-facade"
        {...(styleprops || {})}
      >
        <Span flex={'auto'} lineHeight={0} className={'pe-none'}>
          <Span
            className="body-2"
            display="inline-block"
            lineHeight={0}
            width="100%"
            style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
          >
            {displayValue}
          </Span>
        </Span>
        <Span display="flex" alignItems="center">
          <svg
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0636 2.03604L12.0276 3.88622e-05L7.03275 4.99624L2.036 0L0 2.035L7.03275 9.06723L14.0636 2.03604Z"
              fill={props.arrowColor ? colorMap[props.arrowColor] : '#747474'}
            />
          </svg>
        </Span>
      </Div>
    </Div>
  );
};

export const SelectDropdown = makeStyledComponent(SelectDropdownUnstyled);
