import React, { FC, useState, ChangeEvent, ComponentType, useEffect } from 'react';

import { Div, Input, Label, Span } from '../Elements';
import { useTranslations } from '../../../common/hooks/useTranslations';
import ClearbitAutoComplete from './clearbit-autocomplete';
import { inEU } from '../../../common/util/trace';
import { MaybeRedwood } from '../../../config';
import { userInBlockedCountry } from '../../../common/util/blocked-countries';
import { MarketoFormField } from './get-form-data';
import { StyleSystemProps } from '../Elements/Elements';
export type InputType = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
export type CustomInputElement = ComponentType<
  Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'type' | 'onChange' | 'id' | 'required' | 'onBlur' | 'className' | 'onFocus'
  >
>;

export type CustomCheckboxElement = ComponentType<
  {
    labelText: string;
    labelHtmlFor: string;
  } & Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'type' | 'onChange' | 'id' | 'required' | 'checked' | 'className'
  >
>;

export type CustomTextAreaElement = ComponentType<
  Pick<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    'value' | 'type' | 'onChange' | 'id' | 'required' | 'onBlur' | 'className' | 'onFocus'
  >
>;

export type SelectOption = {
  value: string;
  text: string;
};

export type CustomSelectElement = ComponentType<
  { options: OptionType[] } & Pick<
    React.InputHTMLAttributes<HTMLSelectElement>,
    'value' | 'type' | 'onChange' | 'id' | 'required' | 'className' | 'placeholder'
  >
>;
export type CustomLabelElement = ComponentType<
  { text?: string } & React.LabelHTMLAttributes<HTMLLabelElement>
>;
export interface MarketoInputProps {
  data: MarketoFormField;
  LabelElement?: CustomLabelElement;
  ErrorElement?: CustomLabelElement;
  InputElement?: CustomInputElement;
  TextAreaElement?: CustomTextAreaElement;
  SelectElement?: CustomSelectElement;
  CheckboxElement?: CustomCheckboxElement;
  value: string | boolean;
  showError?: boolean;
  children?:
    | React.ReactElement
    | ((props: { ErrorElement: JSX.Element; InputFieldElement: React.ReactNode }) => JSX.Element);
  onBlur?: (el: React.InputHTMLAttributes<HTMLInputElement>['onBlur']) => void;
  onFocus?: (el: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (el: ChangeEvent<InputType>) => void;
  setValue: (value: string | boolean) => void;
  setClearbitData?: (company: string, url: string) => void;
  isEnglishOnly?: boolean;
}

const errorProps = (
  hasError: boolean | undefined
):
  | Pick<
      StyleSystemProps,
      'borderColor' | 'outlineColor' | 'outlineStyle' | 'outlineWidth' | 'outlineInnerOffset'
    >
  | {} =>
  !!hasError
    ? {
        borderColor: 'transparent',
        outlineColor: 'red0',
        outlineStyle: 'solid',
        outlineWidth: 2,
        outlineInnerOffset: 2,
      }
    : {};

export type OptionType = { label: string; value: string };
const LocalDefaultCountry = 'US';
export const MarketoInputElement: FC<MarketoInputProps> = ({
  data,
  InputElement,
  TextAreaElement,
  SelectElement,
  CheckboxElement,
  LabelElement,
  setValue,
  ErrorElement,
  value,
  showError,
  children,
  onBlur = () => null,
  onFocus = () => null,
  onChange = () => null,
  setClearbitData = (company: string, url: string) => null,
  isEnglishOnly,
}) => {
  const redwood = (window as MaybeRedwood).redwood || { country: LocalDefaultCountry }; //
  const [errorVisible, setErrorVisibile] = useState(true);
  const translate = useTranslations();

  //   useEffect(() => {
  //     if (data.dataType === 'checkbox' && data.rawId === 'marketingOptInConsent') {
  //       (data.originalFormReference as any).checked = false; // opt-in
  //     }
  //   }, []);

  const setFormValue = (val: string) => {
    setValue(val);
  };

  const onTextChange = (e: ChangeEvent<InputType>) => {
    setFormValue(e.target.value);
    onChange(e);
  };

  const onCheckboxChange = (e: ChangeEvent<InputType>) => {
    setFormValue((e.target as any).checked);
  };

  const onSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFormValue(e.target.value);
  };

  const countryErrorMessage =
    'We are currently unable to serve businesses or organizations based in your selected country. Please email legal@cloudflare.com if you need additional assistance.';

  const errorElement = ErrorElement ? (
    <Span hidden={!showError} key={`error-${data.id}`}>
      <ErrorElement>
        <Span
          dangerouslySetInnerHTML={{
            __html: isEnglishOnly
              ? data.validationMessage
              : translate(
                  data.id === 'Country'
                    ? 'marketo_blocked_countries'
                    : (data.validationMessage as any)
                ),
          }}
        />
      </ErrorElement>
    </Span>
  ) : (
    <Span hidden={!showError}>
      <Label marginLeft={1} color="red0" hidden={errorVisible}>
        <Span
          dangerouslySetInnerHTML={{
            __html: isEnglishOnly
              ? data.validationMessage
              : translate(data.validationMessage as any),
          }}
        />
      </Label>
    </Span>
  );
  const returnTuple = [];

  if (data.dataType === 'select') {
    const options = data.fieldMetaData?.values?.map((f: OptionType, i: number) => ({
      label: f.label,
      value: f.value,
    })) as OptionType[];
    if (!options) {
      return null;
    }
    if (data.id === 'Country' && !userInBlockedCountry(redwood.country)) {
      return null;
    }
    if (SelectElement) {
      returnTuple.push(
        <SelectElement
          key={data.id}
          options={options}
          value={value as string}
          onChange={onSelectChange}
          id={data.id}
          className={` ${showError ? 'hasError' : ''}`}
          {...errorProps(showError)}
          required={data.required}
          type={data.dataType}
        />
      );
    } else {
      returnTuple.push(
        <select onChange={onSelectChange} key={data.id}>
          {options.map((o, i) => (
            <option key={`${o.value}-${i}`} selected={o.value === value} value={o.value}>
              {isEnglishOnly ? data.validationMessage : translate(o.label)}
            </option>
          ))}
        </select>
      );
    }
  }
  if (data.dataType === 'text' || data.dataType === 'email' || data.dataType === 'telephone') {
    if (InputElement) {
      returnTuple.push(
        <>
          <InputElement
            key={data.id}
            onBlur={onBlur as any}
            value={value as string}
            onChange={onTextChange}
            id={data.id}
            required={data.required}
            onFocus={onFocus}
            type={data.dataType}
            className={` ${showError ? 'hasError' : ''}`}
            {...errorProps(showError)}
          />
          {data.id?.indexOf('Company') > -1 && (
            <ClearbitAutoComplete
              value={value as string}
              setData={(company: string, url: string) => {
                onTextChange({ target: { value: company } } as ChangeEvent<InputType>);
                setClearbitData(company, url);
              }}
            />
          )}
        </>
      );
    } else {
      returnTuple.push(
        <input
          key={data.id}
          onBlur={onBlur as any}
          type={data.dataType}
          value={value as string}
          onChange={onTextChange}
          id={data.id}
          name={data.id}
          onFocus={onFocus}
          required={data.required}
          className={` ${showError ? 'hasError' : ''}`}
          {...errorProps(showError)}
        />
      );
    }
  }
  if (data.dataType === 'textArea') {
    if (TextAreaElement) {
      returnTuple.push(
        <TextAreaElement
          key={data.id}
          onBlur={onBlur as any}
          value={value as string}
          onChange={onTextChange}
          id={data.id}
          required={data.required}
          className={` ${showError ? 'hasError' : ''}`}
          {...errorProps(showError)}
        />
      );
    } else {
      returnTuple.push(
        <textarea
          key={data.id}
          onBlur={onBlur as any}
          className={`Input  ${showError ? 'hasError' : ''}`}
          id={data.id}
          name={data.id}
          value={value as string}
          onChange={onTextChange}
        ></textarea>
      );
    }
  }
  if (data.dataType === 'checkbox') {
    if (data.id === 'marketingOptInConsent') {
      if (!redwood?.country) {
        return null; // do not show consent form
      }
      if (!inEU(redwood?.country)) {
        if (value !== true) {
          setValue(true); // default to true
        }
        // (data.originalFormReference as any).checked = true;
        return null; // do not show consent form
      }

      // Invoke change to propogate upstream/hidden forms
      // onCheckboxChange({ target: { checked: dataChecked } } as ChangeEvent<InputType>);
    }
    if (CheckboxElement) {
      //marketingOptInConsent
      returnTuple.push(
        <CheckboxElement
          key={data.id}
          checked={value as boolean}
          id={data.id}
          value="yes"
          className={`${showError ? 'hasError' : ''} mr2`}
          {...errorProps(showError)}
          labelHtmlFor={data.id}
          labelText={translate(data.label || '') as string}
          onChange={onCheckboxChange}
        />
      );
    } else {
      returnTuple.push(
        <Div>
          <Input
            key={data.id}
            type="checkbox"
            id={data.id}
            checked={value as boolean}
            name={data.id}
            value="yes"
            className={` ${showError ? 'hasError' : ''}`}
            onChange={onCheckboxChange as any}
            marginRight={2}
          />
          <MarketoLabel isEnglishOnly={isEnglishOnly} data={data} LabelElement={LabelElement} />
        </Div>
      );
    }
  }

  if (typeof children === 'function') {
    return children({
      ErrorElement: errorElement,
      InputFieldElement: returnTuple[0],
    });
  }
  if (data.required || data.id === 'Country') {
    returnTuple.push(errorElement);
  }
  return <>{returnTuple}</>;
};

export const MarketoLabel: FC<Pick<
  MarketoInputProps,
  'data' | 'LabelElement' | 'isEnglishOnly'
>> = ({ data, LabelElement, isEnglishOnly }) => {
  const translate = useTranslations();
  if (!data.label) return null;

  if (LabelElement) {
    return (
      <LabelElement
        htmlFor={data.id}
        text={
          isEnglishOnly
            ? data.required
              ? `${data.label} *`
              : data.label
            : (translate(data.required ? `${data.label} *` : data.label) as string)
        }
      />
    );
  }
  return (
    <Label htmlFor={data.id}>
      {isEnglishOnly
        ? data.required
          ? `${data.label} *`
          : data.label
        : translate(data.required ? `${data.label} *` : data.label)}
    </Label>
  );
};

export const MarketoInput: FC<MarketoInputProps> = ({
  data,
  SelectElement,
  InputElement,
  CheckboxElement,
  TextAreaElement,
  LabelElement,
  ErrorElement,
  showError,
  setValue,
  onBlur,
  onChange,
  value,
  onFocus,
  setClearbitData,
  isEnglishOnly,
}) => {
  const [focused, setFocused] = useState(false);
  const redwood = (window as MaybeRedwood).redwood || { country: LocalDefaultCountry }; //
  let label: any = null;
  if (data.dataType !== 'checkbox') {
    if (data.id !== 'Country' || userInBlockedCountry(redwood.country)) {
      label = (
        <MarketoLabel data={data} LabelElement={LabelElement} isEnglishOnly={isEnglishOnly} />
      );
    }
  }

  return (
    <>
      {(!inEU(redwood?.country) && data.id === 'marketingOptInConsent') ||
      (data.id === 'Country' && !userInBlockedCountry(redwood.country)) ? null : (
        <Div
          position="relative"
          className={`input-container ${value ? 'has-value' : ''} ${
            showError ? 'has-error' : 'mb2'
          }`}
        >
          <MarketoInputElement
            key={data.id}
            isEnglishOnly={isEnglishOnly}
            onBlur={e => {
              if (onBlur) {
                onBlur(e);
              }
              setFocused(false);
            }}
            onChange={onChange}
            data={data}
            InputElement={InputElement}
            TextAreaElement={TextAreaElement}
            setValue={setValue}
            SelectElement={SelectElement}
            CheckboxElement={CheckboxElement}
            LabelElement={LabelElement}
            ErrorElement={ErrorElement}
            showError={showError}
            setClearbitData={setClearbitData}
            value={value}
            onFocus={() => setFocused(true)}
          >
            {props => (
              <>
                <Div position="relative">
                  <Div
                    display="flex"
                    alignItems="center"
                    position="absolute"
                    className={`label-container ${value || focused ? 'raised-label' : ''}`}
                    style={{
                      height: value || focused || data.dataType === 'textArea' ? '' : '100%',
                    }}
                  >
                    {data.dataType !== 'select' && (
                      <Div
                        marginHorizontal={2}
                        className={` ${showError ? 'red0' : ''} ${focused ? 'focused' : ''} `}
                        hidden={data.dataType === 'textArea' && !!value}
                        style={{ marginTop: data.dataType === 'textArea' ? '12px' : 0 }}
                      >
                        {label}
                      </Div>
                    )}
                  </Div>
                  <Div lineHeight={0}>{props.InputFieldElement}</Div>
                </Div>
                <Div marginTop={1}>{props.ErrorElement}</Div>
              </>
            )}
          </MarketoInputElement>
        </Div>
      )}
    </>
  );
};
