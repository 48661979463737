import React, { Fragment, FC, useState, useEffect } from 'react';
import { getSparrowUserId } from '../../../common/analytics/sparrow';
import {
  getGAtrackingField,
  getOptimizeExperiments,
  trackFormSubmit,
} from '../../../common/analytics/trackers';
import { useConfiguration } from '../../../common/hooks/useConfiguration';
import { useLocale } from '../../../common/hooks/useLocale';
import { useTranslations } from '../../../common/hooks/useTranslations';
import { isUTMParam, useUTMParams, UTMParams } from '../../../common/hooks/useUTMParams';
import { getCookie } from '../../../common/util/getCookie';
import { interpolateObject } from '../../../common/util/interpolateGlobalVariables';
import { isAsianLocale } from '../../../common/util/locales';
import { config, MaybeRedwood } from '../../../config';
import { MarketoFormEmbedded } from '../../../contentful/content-types/marketo-form-embedded/marketo-form-embedded.interface';
import { Button, Div, H2, P, Row, Col } from '../Elements';
import { MarkdownBlock } from '../MarkdownBlock';
import { FormPayload, getMarketoFormData } from './get-form-data';
import {
  CustomCheckboxElement,
  CustomInputElement,
  CustomLabelElement,
  CustomSelectElement,
  CustomTextAreaElement,
  MarketoInput,
} from './marketo-input';
import {
  getGoogleData,
  getTraceResults,
  getUtmData,
  swapFields,
  useMarketoPrivacyPolicy,
} from './marketo.utils';
import { validateInput } from './validations';
import { sha256ToHex } from '../../../common/util/shaToHex';
import { isValidHttpUrl } from '../../../common/util/is-valid-url.util';
import { gtm } from '../../../scripts/gtm';

export type MarketoFormProps = {
  title?: string;
  subtitle?: string;
  buttonText?: string;
  marketoData: Pick<
    MarketoFormEmbedded,
    | 'marketoFormId'
    | 'marketoFormLeadSource'
    | 'marketoFormLeadSourceDetail'
    | 'partnerPrivacyPolicyName'
    | 'marketoFormSuccessRedirectUrl'
    | 'showCommentBox'
    | 'marketoFormSubmitButtonText'
  >;
  additionalSubmitFormData?: { [key: string]: string | number | boolean };
  InputElement?: CustomInputElement;
  onSuccess: () => void;
  TextAreaElement?: CustomTextAreaElement;
  SelectElement?: CustomSelectElement;
  LabelElement?: CustomLabelElement;
  CheckboxElement?: CustomCheckboxElement;
  ErrorElement?: CustomLabelElement;
  SuccessElement?: React.FC | null;
  isEnglishOnly?: boolean;
};

const getContentfulMarketoData = (mktoData: MarketoFormProps['marketoData']) => {
  const data: any = {};
  if (mktoData.marketoFormLeadSource) {
    data['LeadSource'] = mktoData.marketoFormLeadSource;
  }

  if (mktoData.marketoFormLeadSourceDetail) {
    data['Lead_Source_Detail__c'] = mktoData.marketoFormLeadSourceDetail;
  }

  return data;
};

export const MarketoForm: FC<MarketoFormProps> = ({
  title,
  subtitle,
  marketoData,
  additionalSubmitFormData,
  InputElement,
  TextAreaElement,
  onSuccess,
  SelectElement,
  CheckboxElement,
  SuccessElement,
  LabelElement,
  ErrorElement,
  buttonText,
  isEnglishOnly,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState<null | FormPayload>(null);
  const [formInputVals, setFormInputVals] = useState<{ [key: string]: string | boolean }>({});
  const [formErrors, setFormErrors] = useState<{ [key: string]: boolean }>({});
  const [formVisible, setFormVisible] = useState(true);
  const [successMessageVisible, setSuccessMessageVisibile] = useState<boolean>(false);
  const locale = useLocale();
  const translate = useTranslations();
  const utmParams = useUTMParams();
  const siteConfig = useConfiguration();

  let rv1Data: any = {};

  const setInputVal = (key: string) => (val: string | boolean) => {
    setFormInputVals({ ...formInputVals, [key]: val });
  };

  const convertToString = (o: string | number | boolean | object | undefined | null) => {
    if (!o) {
      return '';
    }
    if (typeof o === 'object') {
      return JSON.stringify(o);
    }

    if (typeof o === 'boolean' || typeof o === 'number') {
      return o.toString();
    }
    return o.toString();
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (isSubmitting) return;

    const rv1 = (window as MaybeRedwood).redwood?.rv1;
    if (rv1) {
      rv1Data['botManagementrv1'] = rv1;
    }

    e.preventDefault();

    if (!doValidation()) {
      return;
    }
    if (!marketoData.marketoFormId) {
      return;
    }

    setIsSubmitting(true);

    const visibleFieldsPayload = data?.fields.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.id]: formInputVals[curr.id],
      };
    }, {});

    const fieldsPayload: { [key: string]: string | number | boolean } = {
      ...visibleFieldsPayload,
      ...(additionalSubmitFormData || {}),
      ...getTraceResults(),
      ...getGoogleData(),
      ...getUtmData(utmParams),
      ...getContentfulMarketoData(marketoData),
      ...rv1Data,
      _mktoReferrer: window.location.href.replace('#', ''),
      munchkinId: config.marketo_form_munchkin,
      formVid: marketoData.marketoFormId,
      formid: marketoData.marketoFormId,
      showCommentBox: marketoData.showCommentBox,
    };

    const checksumFields: string[] = [];
    const checksumVals: string[] = [];
    let body = Object.entries(fieldsPayload)
      .map(([key, val], i) => {
        checksumFields.push(key);
        checksumVals.push(convertToString(val));
        return `${key}=${encodeURIComponent(convertToString(val))}`;
      })
      .join('&');

    // Construct list of fields to be validated serverside by checksum
    body += `&checksumFields=${encodeURIComponent(checksumFields.join(','))}`;
    // Use the same algorithm as forms2 for generating a hash
    const checksum = await sha256ToHex(checksumVals.join('|'), 'SHA-256');
    body += `&checksum=${checksum}`;

    try {
      const result = await fetch(`${config.marketoDomain}/index.php/leadCapture/save2`, {
        method: 'POST',
        headers: {
          'Content-Length': body.length.toString(),
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          Accept: 'application/json',
        },
        body: body,
      });
      gtm({ FormID: marketoData.marketoFormId });
      gtm({ Company: formInputVals.Company });
      gtm({ Website: formInputVals.Website });
      gtm({ JobTitle: formInputVals.Title });
      trackFormSubmit(
        marketoData.marketoFormLeadSource || '',
        'success',
        marketoData.marketoFormSubmitButtonText || ''
      );
      if (result.status > 399) {
        console.error('Error submitting form', result.status, e);
        const x = await result.json();
        console.log(x);
        // @TODO: We have no error screen for forms
        return;
      }

      const { marketoFormSuccessRedirectUrl } = marketoData;
      if (marketoFormSuccessRedirectUrl && isValidHttpUrl(marketoFormSuccessRedirectUrl)) {
        window.location.href = marketoFormSuccessRedirectUrl;
        return;
      }
      setFormVisible(false);
      setSuccessMessageVisibile(true);
      onSuccess();
    } catch (e) {
      console.error('Error submitting form', e);
    } finally {
      setIsSubmitting(false);
    }
  };
  const setOnBlurCallback = (key: string) => () => {
    doValidation(key);
  };
  const setOnChangeCallback = (key: string) => () => {
    doValidation(key);
  };

  const setClearbitDataCallback = (company: string, url: string) => {
    setFormInputVals({ ...formInputVals, Company: company, Website: url });
  };

  const doValidation = (key?: string) => {
    if (key) {
      const dataField = data?.fields.find(x => x.id === key);
      if (!dataField) {
        return false;
      }
      const valid = validateInput(dataField, formInputVals[key] as string);

      setFormErrors({ ...formErrors, [dataField.id]: !valid });
      return valid;
    }
    const validationState: { [key: string]: boolean } = {};
    let isValid = true;
    let hadError = false;
    data?.fields.forEach(d => {
      isValid = !!validateInput(d, formInputVals[d.id] as string);
      validationState[d.id] = !isValid;
      if (!hadError && !isValid) {
        hadError = true;
      }
    });
    setFormErrors(validationState);
    return !hadError;
  };

  const privacyPolicy =
    marketoData.marketoFormId &&
    useMarketoPrivacyPolicy(
      marketoData.marketoFormId,
      marketoData.partnerPrivacyPolicyName,
      isEnglishOnly
    );

  useEffect(() => {
    (async () => {
      if (marketoData?.marketoFormId) {
        let data = await getMarketoFormData(marketoData.marketoFormId, siteConfig.coreApiV1);
        if (!data) {
          console.warn('no marketo data found');
          return;
        }
        if (isAsianLocale(locale)) {
          data.fields = swapFields(data.fields, 'id', 'FirstName', 'LastName');
        }

        if (!marketoData.showCommentBox) {
          data = {
            ...data,
            fields: [...data.fields.filter(field => field.id !== 'commentCapture')],
          };
        }
        setData(data);
      }
    })();
  }, []);
  return (
    <>
      {successMessageVisible && SuccessElement ? (
        <SuccessElement />
      ) : (
        <section hidden={!formVisible}>
          <form onSubmit={onFormSubmit} noValidate>
            <Div marginBottom={6}>
              {title && (
                <H2 lineHeight="title" marginBottom={2}>
                  {title}
                </H2>
              )}
              {subtitle && (
                <P fontSize={2} margin={0} fontWeight={4} lineHeight={'copy'}>
                  {subtitle}
                </P>
              )}
            </Div>

            <Div className="flex flex-wrap">
              {data &&
                data.fields
                  .filter(input => input.id === 'FirstName' || input.id === 'LastName')
                  .map(f => (
                    <Div className={`w-50-ns w-100 ${f.id === 'FirstName' ? 'pr2-ns' : ''}`}>
                      <MarketoInput
                        key={f.id}
                        onBlur={setOnBlurCallback(f.id)}
                        onChange={setOnChangeCallback(f.id)}
                        value={formInputVals[f.id]}
                        setValue={setInputVal(f.id)}
                        data={f}
                        InputElement={InputElement}
                        TextAreaElement={TextAreaElement}
                        SelectElement={SelectElement}
                        LabelElement={LabelElement}
                        CheckboxElement={CheckboxElement}
                        showError={formErrors[f.id]}
                        ErrorElement={ErrorElement}
                        setClearbitData={setClearbitDataCallback}
                        isEnglishOnly={isEnglishOnly}
                      />
                    </Div>
                  ))}
            </Div>
            {data &&
              data.fields
                .filter(input => input.id !== 'FirstName')
                .filter(input => input.id !== 'LastName')
                .map(f => (
                  <MarketoInput
                    key={f.id}
                    onBlur={setOnBlurCallback(f.id)}
                    onChange={setOnChangeCallback(f.id)}
                    value={formInputVals[f.id]}
                    setValue={setInputVal(f.id)}
                    data={f}
                    InputElement={InputElement}
                    TextAreaElement={TextAreaElement}
                    SelectElement={SelectElement}
                    LabelElement={LabelElement}
                    CheckboxElement={CheckboxElement}
                    showError={formErrors[f.id]}
                    ErrorElement={ErrorElement}
                    setClearbitData={setClearbitDataCallback}
                    isEnglishOnly={isEnglishOnly}
                  />
                ))}
            <Button
              lineHeight="copy"
              fontWeight={7}
              fontSize={3}
              width={'100%'}
              backgroundColor="orange1"
              color="white"
              border="none"
              paddingVertical={2}
              className="pointer dim"
              marginTop={4}
              marginBottom={0}
              type="submit"
              disabled={isSubmitting}
              opacity={isSubmitting ? 0.5 : undefined}
            >
              {marketoData.marketoFormSubmitButtonText
                ? marketoData.marketoFormSubmitButtonText
                : isEnglishOnly
                ? data?.form.buttonLabel
                : translate(data?.form.buttonLabel as string)}
            </Button>
            <Div>
              {!!privacyPolicy && (
                <MarkdownBlock
                  renderers={{
                    paragraph: ({ children }) => (
                      <P fontWeight={4} lineHeight="copy" fontSize={1} marginBottom={0}>
                        {children}
                      </P>
                    ),
                  }}
                  source={interpolateObject(privacyPolicy, {
                    PRIVACY_POLICY_URL: 'https://www.cloudflare.com/privacypolicy/',
                    PARTNER: marketoData.partnerPrivacyPolicyName,
                  })}
                />
              )}
            </Div>
          </form>
        </section>
      )}
    </>
  );
};
